<template>
    <div class="page">
        <div class="content">
            <div class="top">
                <Input v-model="storePrams.keywords" suffix="ios-search" placeholder="请输入姓名、手机号" style="width: 15vw;" />
                <Select v-model="storePrams.status" style="width: 10vw;margin: 0 30px;" placeholder="全部">
                    <Option v-for="item in disableList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
                <Button type="primary" style="background: #296FE1;" @click="search">搜索</Button>
            </div>
            <Button type="primary" style="background: #296FE1;margin-bottom: 20px;" @click="goCreate(0)">创建</Button>
            <div style="min-height: 65vh;">
                <Table :columns="columnsTable" :data="dataTable" border>
                    <template slot-scope="{ index }" slot="id">
                        <div>{{ index + 1 }}</div>
                    </template>
                    <template slot-scope="{ row }" slot="signup_count">
                        <div style="display: flex;justify-content: center;">
                            <div class="table-span" @click="goApplication(row)">{{row.signup_count}}</div>
                        </div>
                    </template>
                    <template slot-scope="{ row }" slot="status">
                        <div><i-switch v-model="row.status" @on-change="changeSwitch($event,row)" :true-value="1" :false-value="2"></i-switch></div>
                    </template>
                    <template slot-scope="{ row }" slot="manage">
                        <div style="display: flex;flex-direction: row;align-content: center;justify-content: space-around;">
                            <Button type="primary" ghost @click="goCreate(1,row)" label="small">修改</Button>
                            <Button type="error" ghost @click="deleteItem(row)" label="small">删除</Button>
                        </div>
                    </template>
                </Table>
            </div>
            <Page class="Page" :current="storePrams.page" :page-size="storePrams.limit" @on-change="pageChange" :total="total" show-elevator show-total />
        </div>
        <!-- 创建/修改 - create -->
        <Modal v-model="createModal" :title="modalTitle" width="50">
            <div>
                <Form ref="formInline" :model="formInline" label-position="right" inline :label-width="100" :rules="ruleValidate">
                    <FormItem label="标题" prop="title">
                        <Input type="text" v-model="formInline.title" placeholder="请输入标题" style="width: 15vw;"></Input>
                    </FormItem>
                    <FormItem label="利润" prop="profit">
                        <Input type="text" v-model="formInline.profit" placeholder="请输入利润" style="width: 15vw;"></Input>
                    </FormItem>
                    <FormItem label="客服费" prop="customer_service_free">
                        <Input type="text" v-model="formInline.customer_service_free" placeholder="请输入客服费" style="width: 15vw;"></Input>
                    </FormItem>
                    <FormItem label="推广费" prop="refund_fee">
                        <Input type="text" v-model="formInline.refund_fee" placeholder="请输入推广费" style="width: 15vw;"></Input>
                    </FormItem>
                    <FormItem label="所需人数">
                        <Input type="text" v-model="formInline.num" placeholder="请输入所需人数" style="width: 15vw;"></Input>
                    </FormItem>
                    <FormItem label="福利待遇">
                        <Input type="text" v-model="formInline.positions_tags" placeholder="请输入福利待遇(多个标签用，(中文)隔开)" style="width: 15vw;"></Input>
                    </FormItem>
                    <FormItem label="工资" prop="wages">
                        <div style="display:flex;flex-direction:column;">
                            <div>
                                <RadioGroup v-model="is_book_switch">
                                    <Radio v-for="item in orderList" :label="item.value" :key="item.value"><span>{{item.label}}</span></Radio>
                                </RadioGroup>
                            </div>
                            <div class="wages-input">
                                <Input v-if="is_book_switch == 0" type="text" v-model="formInline.wages" style="width: 250px;" placeholder="请输入工资">
                                    <span slot="append">
                                        <Select v-model="formInline.unit" style="width: 80px" @on-change="typeChange">
                                            <Option v-for="item in typeList" :value="item.value" :key="item.value">{{item.text}}</Option>
                                        </Select>
                                    </span>
                                </Input>
                                <div v-if="is_book_switch == 1" style="display:flex;align-items:center;">
                                    <Input type="text" v-model="wages_small" style="width: 80px;" placeholder="最小薪资" :border="false"></Input>
                                    <span>~</span>
                                    <Input type="text" v-model="wages_large" style="width: 180px;" placeholder="最大薪资" :border="false">
                                        <span slot="append">
                                            <Select v-model="formInline.unit" style="width: 80px;border-left: 1px solid #dcdee2" @on-change="typeChange">
                                                <Option v-for="item in typeList" :value="item.value" :key="item.value">{{item.text}}</Option>
                                            </Select>
                                        </span>
                                    </Input>
                                </div>
                                <div class="unit-lists">
                                    <div v-for="(item, index) in unitList" :key="index" :class="formInline.settle_id == index + 1 ? 'unit-list-active' : 'unit-list'" @click="activeList(index)">{{item}}</div>
                                </div>
                            </div>
                        </div>
                    </FormItem>
                    <FormItem label="岗位图片">
                        <div>
                            <Upload 
                                ref="upload"
                                :show-upload-list="false"
                                :format="['jpg', 'jpeg', 'png', 'gif']"
                                :max-size="2048"
                                :on-format-error="handleFormatError"
                                :on-success="pictureSuccess"
                                :on-progress="handleProgress"
                                :action="uploadImg"
                                :headers="headers"
                                >
                                <div v-if="!formInline.img_id" class="box">
                                    <img class="box-icon" src="@/assets/add_img.png">
                                    <div class="box-p">(750*300)</div>
                                </div>
                                <div v-else style="position: relative;">
                                    <img class="box-img" :src="form.img_path">
                                    <img class="box-del" src="@/assets/del.png" @click.stop="imgDel">
                                </div>
                            </Upload>
                        </div>
                    </FormItem>
                    <FormItem label="职位描述" prop="jobs_desc">
                        <Input type="textarea" v-model="formInline.jobs_desc" placeholder="请输入职位描述" style="width: calc(30vw + 110px)"></Input>
                    </FormItem>
                    <FormItem label="所属类目" prop="category_id">
                        <Select v-model="formInline.category_id" placeholder="请选择所属类目" style="width: 15vw;">
                            <Option v-for="item in categoryList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="所属岗位" prop="post_id">
                        <Select v-model="postId" multiple placeholder="请选择所属岗位" style="width: 15vw;">
                            <Option v-for="item in postList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="公司地址" prop="company_address">
                        <Input type="text" v-model="formInline.company_address" placeholder="请输入公司地址" style="width: 15vw;"></Input>
                    </FormItem>
                    <FormItem label="押金">
                        <Input type="number" v-model="formInline.deposit" placeholder="请输入押金" style="width: 15vw;">
                            <span slot="append">元</span>
                        </Input>
                    </FormItem>
                </Form>
            </div>
            <div slot="footer">
                <Button @click="cancelModal">取消</Button>
                <Button type="primary" style="background: #296FE1;" @click="saveModal">保存</Button>
            </div>
        </Modal>
        <!-- 删除 -->
        <Modal v-model="deleteModal" title="提示">
            <div class="content-modal" style="min-height: 100px;">
                <div class="p-modal">是否确定删除该条信息!</div>
            </div>
            <div slot="footer">
                <Button @click="cancelDelModal">取消</Button>
                <Button type="primary" style="background: #296FE1;" @click="saveDelModal">保存</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { jobsList, jobs_modify_status, jobsDel, jobsCreate, jobs_posts_list, jobs_settle_list, jobs_category_list, other_del_img } from "@/api/index";
import { getCookies } from "@/utils/helper";
import config from "@/config/index";
    export default {
        data() {
            return {
                uploadImg: config.apiUrl + "/pc/other/uploads",
                headers: {"authori-zation-jobs-pc": getCookies("authori-zation-jobs-pc")},
                wages_small:'',
                wages_large:'',
                total: 0,
                storePrams: {
                    page: 1,
                    limit: 30,
                    keywords: '',
                    status: '-1',
                },
                columnsTable: [
                    {title: '#',slot: "id",align:'center',width:'80px'},
                    {title: '标题',key: 'title',align:'center'},
                    {title: '工资(元)',key: 'wages',align:'center'},
                    {title: '报名人数',slot: 'signup_count',align:'center'},
                    {title: '推广费',key: 'refund_fee',align:'center'},
                    {title: '利润(元)',key: 'profit',align:'center'},
                    {title: '上下架',slot: 'status',align:'center'},
                    {title: '操作',slot: 'manage',align:'center'}
                ],
                dataTable: [],
                disableList: [
                    { value: '-1', label: '全部' },
                    { value: '1', label: '上架' },
                    { value: '2', label: '下架' }
                ],
                createModal: false,
                modalTitle: '',
                postId: [],
                formInline: {
                    title: '',
                    wages: '',
                    profit: '',
                    refund_fee: '',
                    customer_service_free: '',
                    num: '',
                    company_address: '',
                    positions_tags: '',
                    category_id: '',
                    post_id: '',
                    jobs_desc: '',
                    deposit: '',
                    img_id: '',
                    unit: 1,
                    settle_id: 1,
                    id: ''
                },
                is_book_switch: 0,
                wagesList: [
                    { value: 1, label: '月' },
                    { value: 2, label: '日' },
                    { value: 3, label: '次' },
                    { value: 4, label: '单' }
                ],
                categoryList: [],
                postList: [],
                wayList: [],
                deleteModal: false,
                testId: '',
                form:{img_path: ''},
                orderList:[
                    {label:'固定工资',value:'0'},
                    {label:'范围工资',value:'1'}
                ],
                typeList: [
					{ value: 1, text: "元/小时" },
					{ value: 2, text: "元/天" },
					{ value: 3, text: "元/周" },
					{ value: 4, text: "元/月" },
					{ value: 5, text: "元/次" },
					{ value: 6, text: "元/单" }
				],
                unitList: ['日结', '月结', '周结', '完工结', '面议'],
                ruleValidate: {
                    title: [
                        { required: true, message: '请输入标题', trigger: 'blur' }
                    ],
                    profit: [
                        { required: true, message: '请输入利润', trigger: 'blur' }
                    ],
                    customer_service_free: [
                        { required: true, message: '请输入客服费', trigger: 'blur' }
                    ],
                    refund_fee: [
                        { required: true, message: '请输入推广费', trigger: 'blur' }
                    ],
                    wages: [
                        { required: true, message: '请输入工资', trigger: 'blur' }
                    ],
                    jobs_desc: [
                        { required: true, message: '请输入职位描述', trigger: 'blur' }
                    ],
                    category_id: [
                        { required: true, message: '请选择所属类目', trigger: 'blur' }
                    ],
                    post_id: [
                        { required: true, message: '请选择所属岗位', trigger: 'blur' }
                    ],
                    company_address: [
                        { required: true, message: '请输入公司地址', trigger: 'blur' }
                    ]
                }
            }
        },
        created(){},
        mounted(){
            this.jobsList()
            this.jobs_posts_list()
            this.jobs_settle_list()
            this.jobs_category_list()
        },
        methods: {
            typeChange(e){
                this.formInline.unit = e
            },
            activeList(i){
				this.formInline.settle_id = i + 1
			},
            imgDel(){
                other_del_img({id: this.testId}).then(res => {
                    this.formInline.img_id = ''
                    this.form.img_path = ''
                    this.$Message.success(res.msg);
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            jobs_posts_list(){
                jobs_posts_list().then(res => {
                    this.postList = res.data
                })
            },
            jobs_settle_list(){
                jobs_settle_list().then(res => {
                    this.wayList = res.data
                })
            },
            jobs_category_list(){
                jobs_category_list().then(res => {
                    this.categoryList = res.data
                })
            },
            search(){
                this.jobsList()
            },
            jobsList(){
                jobsList(this.storePrams).then(res => {
                    this.dataTable = res.data.data
                    this.total = res.data.total
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            pageChange(index) {
                this.storePrams.page = index;
                this.jobsList()
            },
            changeSwitch(status, e) {
                let data = {
                    id: e.id,
                    status: status
                }
                jobs_modify_status(data).then(res => {
                    this.$Message.success(res.msg);
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            goCreate(i,e){
                this.createModal = true
                if(i == 0){
                    this.modalTitle = '创建'
                    this.formInline.id = ''
                    this.formInline.title = ''
                    this.formInline.wages = ''
                    this.formInline.refund_fee = ''
                    this.formInline.customer_service_free = ''
                    this.formInline.profit = ''
                    this.formInline.img_id = ''
                    this.form.img_path = ''
                    this.formInline.unit = 1
                    this.formInline.num = ''
                    this.formInline.company_address = ''
                    this.formInline.positions_tags = ''
                    this.formInline.category_id = ''
                    this.formInline.settle_id = 1
                    this.formInline.jobs_desc = ''
                    this.formInline.deposit = ''
                    this.formInline.post_id = ''
                    this.postId = []
                    this.wages_small = ''
                    this.wages_large = ''
                    this.is_book_switch = '0'
                }else if (i == 1) {
                    this.modalTitle = '修改'
                    this.testId = e.id
                    this.formInline.title = e.title
                    this.formInline.id = e.id
                    this.formInline.wages = e.wages
                    this.formInline.refund_fee = e.refund_fee 
                    this.formInline.customer_service_free = e.customer_service_free 
                    this.formInline.profit = e.profit
                    this.formInline.settle_id = e.settle_id
                    this.formInline.unit = e.unit
                    this.formInline.num = e.num
                    this.formInline.company_address = e.company_address
                    this.formInline.positions_tags = e.positions_tags
                    this.formInline.category_id = e.category_id
                    this.formInline.jobs_desc = e.jobs_desc
                    this.formInline.deposit = e.deposit
                    this.postId = e.posts
                    let string = e.posts.join()   
                    this.formInline.post_id = string
                    this.is_book_switch = '0'
                    const data = this.formInline.wages.split('~')
                    if(e.img_id == 0){
                        this.formInline.img_id = ''
                        this.form.img_path = ''
                    }else{
                        this.formInline.img_id = e.img_id
                        this.form.img_path = e.images.path
                    }
                    if(data.length > 1){
                        this.wages_small = data[0]
                        this.wages_large = data[1]
                        this.is_book_switch = '1'
                    }
                }
            },
            cancelModal(){
                this.createModal = false
            },
            saveModal(){
                if(this.wages_small || this.wages_large){
                    this.formInline.wages = this.wages_small + '~' + this.wages_large
                }
                this.formInline.post_id = this.postId.join()
                jobsCreate(this.formInline).then(res => {
                    this.createModal = false
                    this.jobsList()
                    this.$Message.success(res.msg);
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            deleteItem(e){
                this.deleteModal = true
                this.testId = e.id
            },
            cancelDelModal(){
                this.deleteModal = false
            },
            saveDelModal(){
                jobsDel({id: this.testId}).then(res => {
                    this.jobsList()
                    this.deleteModal = false
                    this.$Message.success(res.msg);
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            goApplication(e){
                this.$router.push({ path: '/admin/application', query: { id: e.id } })
            },
            //文件上传类型错误
            handleFormatError() {
                this.$Message.warning("暂不支持上传此格式");
            },
            // 文件上传时
            handleProgress() {
                const msg = this.$Message.loading({
                    content: "上传中...",
                    duration: 0,
                });
                setTimeout(msg, 3000);
            },
            // 上传成功
            pictureSuccess(e) {
                this.formInline.img_id = e.data.id
                this.form.img_path = e.data.path
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
        }
    }
</script>

<style lang="scss" scoped>
.unit-lists{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #e6e6e6;
}
.unit-list-active{
    background: rgba(41,111,225,0.12);
    border: 1px solid rgba(41,111,225,0.12);
    border-radius: 5px;
    padding: 0 10px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #296FE1;
    margin-right: 10px;
    cursor: pointer;
}
.unit-list{
    background: #E9E9E9;
    border: 1px solid #E9E9E9;
    border-radius: 5px;
    padding: 0 10px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-right: 10px;
    cursor: pointer;
}
.wages-input{
    margin-top: 10px;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    padding: 10px;
}
.page{
    width: 100%;
    height: 100%;
    background-color: #E9F0F2;
    padding: 20px;
}
.content{
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
}
.top{
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-bottom: 20px;
}
.table-btn{
    width: 48px;
    height: 24px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #296FE1;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #296FE1;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
}
.table-span{
    width: 72px;
    height: 28px;
    border-radius: 2px;
    border: 1px solid #999999;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 28px;
    text-align: center;
    cursor: pointer;
}
.Page{
    margin-top: 20px;
    text-align: right;
}
.content-modal{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.p-modal{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 18px;
    color: #333333;
}
.box{
    width: 80px;
    height: 80px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #B8C4D1;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.box-icon{
    width: 24px;
    height: 24px;
}
.box-p{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #CCCCCC;
}
.box-img{
    width: 80px;
    height: 80px;
    border-radius: 4px;
}
.box-del{
    width: 18px;
    height: 18px;
    position: absolute;
    top: -9px;
    right: -9px;
    cursor: pointer;
}
::v-deep .ivu-form-item-content{
    display: flex;
}
//表头背景颜色
::v-deep .ivu-table-header thead tr th {
    background: #F5F9FA;
}
// table中所有的边框 
::v-deep .ivu-table td{
    border-left: none;
    border-right: none;
}
//表头字体颜色
::v-deep .ivu-table-wrapper .ivu-table .ivu-table-header tr th {
    color: #666666 !important;
}
</style>